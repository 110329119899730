import React from 'react';
import uuid from 'uuid';
import StyledContact from '../styles/StyledContact';

const links = [
  {
    name: 'facebook',
    anchor: 'https://www.facebook.com/scruffypuppybooks',
    image: 'iconfinder_1_3694241.svg'
  },
  {
    name: 'instagram',
    anchor: 'https://www.instagram.com/scruffy__puppy/',
    image: 'iconfinder_31_3694238.svg'
  },
  {
    name: 'youtube',
    anchor: 'https://www.youtube.com/channel/UCOeslze37tlLiN-JVE-Ovhg',
    image: 'iconfinder_30_3694237.svg'
  }
];

const SocialLink = ({ anchor, image, name }) => (
  <a href={anchor} target="_blank" rel="noopener noreferrer">
    <img src={`./images/${image}`} alt={name} />
  </a>
);

const Contact = () => (
  <StyledContact>
    <div className="container">
      <img src="./images/puppy-ipad.png" alt="puppy holding an iPad" />
      <p className="funFont">
        Let's be friends.
        <br />
        Follow me and Kitty!
      </p>
      <div className="social">
        {links.map(link => (
          <SocialLink
            anchor={link.anchor}
            image={link.image}
            key={uuid.v4()}
            name={link.name}
          />
        ))}
      </div>
    </div>
  </StyledContact>
);

export default Contact;
