import styled from 'styled-components';

const StyledWelcome = styled.header`
  align-items: center;
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.bs};
  border-bottom: 5px solid ${props => props.theme.pink};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 2rem 2rem;

  .header__puppy {
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 3rem;
    margin: 0;
    text-align: center;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.2;
  }

  @media (min-width: 48em) {
    flex-direction: row-reverse;
    padding: 4rem 1rem 2rem 3rem;

    .header__puppy {
      margin-right: 1.5rem;
      transform: scaleX(-1);
    }

    .textcopy {
      width: 70%;
    }
  }
`;

export default StyledWelcome;
