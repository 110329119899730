import React from 'react';
import Header from '../components/Header';
import Library from '../components/Library';
import Contact from '../components/Contact';
import Welcome from '../components/Welcome';
import MailList from '../components/MailList';

const Home = () => (
  <>
    <Header />
    <Welcome />
    <main role="main">
      <h1 className="visually-hidden">Welcome to My Scruffy Puppy</h1>
      <Library />
      <MailList />
      <Contact />
    </main>
  </>
);

export default Home;
