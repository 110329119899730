import styled from 'styled-components';

const StyledVideoPlayer = styled.div`
  margin-bottom: 1.5rem;

  .playlist-thumbs {
    display: flex;
    justify-content: center;
    padding-top: 1rem;

    @media (min-width: 31.25em) {
      justify-content: flex-start;
      padding-left: 1rem;
    }

    img {
      align-self: center;
      border: 1px solid ${props => props.theme.black};
      margin-right: 0.5rem;
      max-width: 150px;

      &:hover {
        cursor: pointer;
      }
      &:hover,
      &:focus {
        border-color: ${props => props.theme.pink};
      }
    }
  }
`;

export default StyledVideoPlayer;
