import React from 'react';
import styled from 'styled-components';

import Header from '../components/Header';

const StyledMain = styled.main`
  background-color: ${props => props.theme.white};
  border-top: 5px solid ${props => props.theme.blue};
  padding: 3rem;

  p {
    margin-top: 0;
  }
`;

const Privacy = () => (
  <>
    <Header small />
    <StyledMain role="main">
      <h1 className="funFont">Privacy Policy</h1>
      <p>Effective date: October 23, 2019</p>
      <p>
        My Scruffy Puppy ("us", "we", or "our") operates the
        http://myscruffypuppy.com website (hereinafter referred to as the
        "Service").
      </p>
      <p>
        This page informs you of our policies regarding the collection, use, and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data.
      </p>
      <p>
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy.
      </p>

      <h2>Definitions</h2>
      <ul>
        <li>
          <p>
            <strong>Service</strong>
          </p>
          <p>
            Service is the http://myscruffypuppy.com website operated by My
            Scruffy Puppy
          </p>
        </li>
        <li>
          <p>
            <strong>Personal Data</strong>
          </p>
          <p>
            Personal Data means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </p>
        </li>
        <li>
          <p>
            <strong>Usage Data</strong>
          </p>
          <p>
            Usage Data is data collected automatically either generated by the
            use of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit).
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies</strong>
          </p>
          <p>
            Cookies are small files stored on your device (computer or mobile
            device).
          </p>
        </li>
      </ul>

      <h2>Information Collection and Use</h2>
      <p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>

      <h3>Types of Data Collected</h3>

      <h4>Personal Data</h4>
      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:
      </p>

      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Cookies and Usage Data</li>
      </ul>

      <h4>Usage Data</h4>

      <p>
        We may also collect information how the Service is accessed and used
        ("Usage Data"). This Usage Data may include information such as your
        computer's Internet Protocol address (e.g. IP address), browser type,
        browser version, the pages of our Service that you visit, the time and
        date of your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data.
      </p>

      <h4>Tracking & Cookies Data</h4>
      <p>
        We do not use cookies or similar tracking technologies to track the
        activity on our Service.
      </p>

      <h2>Use of Data</h2>

      <p>My Scruffy Puppy uses the collected data for various purposes:</p>
      <ul>
        <li>To provide and maintain the Service</li>
        <li>To notify you about changes to our Service</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li>To provide customer care and support</li>
        <li>
          To provide analysis or valuable information so that we can improve the
          Service
        </li>
        <li>To monitor the usage of the Service</li>
        <li>To detect, prevent and address technical issues</li>
      </ul>

      <h2>Transfer Of Data</h2>
      <p>
        Your information, including Personal Data, may be transferred to - and
        maintained on - computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.
      </p>
      <p>
        If you are located outside United States and choose to provide
        information to us, please note that we transfer the data, including
        Personal Data, to United States and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        My Scruffy Puppy will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Privacy Policy
        and no transfer of your Personal Data will take place to an organization
        or a country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>

      <h2>Disclosure Of Data</h2>

      <h3>Legal Requirements</h3>
      <p>
        My Scruffy Puppy may disclose your Personal Data in the good faith
        belief that such action is necessary to:
      </p>
      <ul>
        <li>To comply with a legal obligation</li>
        <li>
          To protect and defend the rights or property of My Scruffy Puppy
        </li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          To protect the personal safety of users of the Service or the public
        </li>
        <li>To protect against legal liability</li>
      </ul>

      <p>
        As an European citizen, under GDPR, you have certain individual rights.
        You can learn more about these guides in the{' '}
        <a
          href="https://termsfeed.com/blog/gdpr/#Individual_Rights_Under_the_GDPR"
          target="_blank"
          rel="noopener noreferrer"
        >
          GDPR Guide
        </a>
        .
      </p>

      <h2>Security of Data</h2>
      <p>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </p>

      <h2>Service Providers</h2>
      <p>
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used.
      </p>
      <p>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>

      <h3>Analytics</h3>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </p>
      <ul>
        <li>
          <p>
            <strong>Google Analytics</strong>
          </p>
          <p>
            Google Analytics is a web traffic analysis tool. You can read the
            Privacy Policy for Google Analtyics here:
            <a
              href="https://policies.google.com/privacy?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://policies.google.com/privacy?hl=en
            </a>
          </p>
        </li>
      </ul>

      <h2>Links to Other Sites</h2>
      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party's site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>

      <h2>Children&#39;s Privacy</h2>
      <p>
        Our Service does not address anyone under the age of 18 ("Children").
      </p>
      <p>
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your Child has provided us with Personal Data, please contact
        us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <ul>
        <li>By email: puppy@myscruffypuppy.com</li>
      </ul>
    </StyledMain>
  </>
);

export default Privacy;
