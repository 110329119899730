import React from 'react';

import StyledWelcome from '../styles/StyledWelcome';

const Welcome = () => (
  <StyledWelcome>
    <div className="header__puppy">
      <img
        src="./images/puppy-sitting-full-@2x.png"
        alt="Scruffy Puppy sitting with a smile"
      />
    </div>
    <div className="textcopy">
      <h1 className="funFont">Hello!</h1>
      <p>
        I'm Scruffy Puppy. I'm so excited! I love company! Take a look around
        and you'll find videos and info about my stories. Join our mailing list
        to get all of the latest news about my adventures.
      </p>
    </div>
  </StyledWelcome>
);

export default Welcome;
