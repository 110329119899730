import React, { useState } from 'react';
import styled from 'styled-components';

import Button from './Button';

const StyledCenterRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 30rem;
  padding: 3rem 0;

  #mc_embed_signup {
    h2 {
      font-size: 2.75rem;
      text-align: center;
    }

    input {
      border: 1px solid ${props => props.theme.blue};
      font-size: 1.2rem;
      padding: 1rem !important;

      &:focus {
        border-color: ${props => props.theme.pink};
      }
    }
  }
`;

const MailchimpSignup = () => {
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [bdMonth, setBdMonth] = useState('');
  const [bdDay, setBdDay] = useState('');
  const [botCheck, setBotCheck] = useState('');

  return (
    <>
      <StyledForm>
        <div id="mc_embed_signup">
          <form
            action="https://myscruffypuppy.us20.list-manage.com/subscribe/post?u=1b9567b1a6d0d650712ad477d&amp;id=a9061345b2"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            // target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <h2 className="funFont">Join Our Newsletter</h2>
              <div className="indicates-required" style={{ fontSize: '.8rem' }}>
                <span className="asterisk">*</span> indicates required
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                ></input>
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">
                  First Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  value={firstname}
                  onChange={e => setFirstname(e.target.value)}
                  name="FNAME"
                  className="required"
                  id="mce-FNAME"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">
                  Last Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                  name="LNAME"
                  className="required"
                  id="mce-LNAME"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-BIRTHDAY-month">Birthday </label>
                <div className="datefield">
                  <span className="subfield monthfield">
                    <input
                      className="birthday "
                      type="text"
                      pattern="[0-9]*"
                      value={bdMonth}
                      onChange={e => setBdMonth(e.target.value)}
                      placeholder="MM"
                      size="2"
                      maxLength="2"
                      name="BIRTHDAY[month]"
                      id="mce-BIRTHDAY-month"
                      style={{ width: '5.25rem' }}
                    />
                  </span>{' '}
                  /{' '}
                  <span className="subfield dayfield">
                    <input
                      className="birthday "
                      type="text"
                      pattern="[0-9]*"
                      value={bdDay}
                      onChange={e => setBdDay(e.target.value)}
                      placeholder="DD"
                      size="2"
                      maxLength="2"
                      name="BIRTHDAY[day]"
                      id="mce-BIRTHDAY-day"
                      style={{ width: '5.25rem' }}
                    />
                  </span>
                  {/* <span className="small-meta nowrap">( mm / dd )</span> */}
                </div>
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: 'none' }}
                />
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_1b9567b1a6d0d650712ad477d_a9061345b2"
                tabIndex="-1"
                value={botCheck}
                onChange={e => setBotCheck(e => e.target.value)}
              />
            </div>
            <StyledCenterRow>
              <div className="clear" style={{ paddingTop: '1rem' }}>
                {/* <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                /> */}
                <Button
                  className="funFont"
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                >
                  Subscribe
                </Button>
              </div>
            </StyledCenterRow>
          </form>
        </div>
      </StyledForm>
    </>
  );
};

export default MailchimpSignup;
