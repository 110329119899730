import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

// import history from '../history';
import StyledVideoPlayer from '../styles/StyledVideoPlayer';

const VideoPlayer = props => {
  const { videos } = props;
  const [videoList, setVideoList] = useState([]);
  const [currentVideo, setCurrentVideo] = useState('');

  useEffect(() => {
    // if (!videos || !videos.length) history.push('/');

    setVideoList(videos);
    setCurrentVideo(videoList[0]);
  }, [videoList, videos]);

  const Video = () => {
    return (
      <video
        preload="true"
        controls
        poster={`./images/${currentVideo}-poster.jpg`}
      >
        <source src={`./videos/${currentVideo}.mp4`} type="video/mp4" />
        <source src={`./videos/${currentVideo}.webm`} type="video/webm" />
      </video>
    );
  };

  const VideoThumbs = () => {
    if (videoList.length > 1) {
      const images = videoList.map((item, index) => {
        return (
          <img
            key={uuid.v4()}
            src={`./images/${item}-poster.jpg`}
            alt="play video"
            onClick={() => {
              setCurrentVideo(videoList[index]);
            }}
          />
        );
      });
      return <div className="playlist-thumbs">{images}</div>;
    }

    return null;
  };

  if (videoList.length) {
    return (
      <StyledVideoPlayer>
        <Video />
        <VideoThumbs />
      </StyledVideoPlayer>
    );
  }

  return <p>Loading...</p>;
};

const mapStateToProps = state => {
  return {
    videos: state.videos
  };
};

export default connect(mapStateToProps)(VideoPlayer);
