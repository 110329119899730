import { useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';

const FontObserver = () => {
  useEffect(() => {
    if (localStorage.getItem('scruffyfonts') === 'true') {
      document.documentElement.className += ' fonts-loaded';
    } else {
      const ldfundamentalFontObserver = new FontFaceObserver('ldfundamental');
      const lsvFontObserver = new FontFaceObserver('LeagueSpartan');
      const lsvVFFontObserver = new FontFaceObserver('LeagueSpartanVariable');

      Promise.all([
        ldfundamentalFontObserver.load(),
        lsvFontObserver.load(),
        lsvVFFontObserver.load()
      ]).then(() => {
        document.documentElement.className += ' fonts-loaded';
        localStorage.setItem('scruffyfonts', 'true');
      });
    }
  });

  return null;
};

export default FontObserver;
