const books = [
  {
    titleLead: 'Scruffy Puppy',
    title: "Get's A Bath",
    image: 'scruffypuppy-getsabath-front_cover.jpg',
    available: true,
    description:
      '<p>There are bubbles everywhere and the shampoo smells like strawberries! Who loves bathtime? Scruffy Puppy does!</p><p>Join in as Scruffy Puppy Gets A Bath and Kitty does his best to keep his fur dry... because everyone knows, kitties do <b>NOT</b> like water.</p>',
    details: {
      format: 'paperback',
      isbn10: '1733774904',
      isbn13: '978-1733774901',
      pages: 34,
      dimensions: '8.5 x 0.1 x 8.5 inches'
    },
    videos: ['trailer_one-978-1-7337749-0-1', '978-1-7337749-0-1-delivery'],
    link: 'https://www.amazon.com/dp/1733774904?ref_=pe_3052080_397514860'
  },
  {
    titleLead: "Scruffy Puppy's",
    title: 'Big Adventure',
    image: 'paperback-978-1-7337749-1-8-cover.jpg',
    available: true,
    note: null,
    description:
      '<p>Scruffy Puppy is out on a big adventure, and no one is more excited then his best friend Kitty.</p>',
    details: {
      format: 'paperback',
      isbn10: '1733774912',
      isbn13: '978-1733774918',
      pages: 41,
      dimensions: '8.5 x 0.1 x 8.5 inches'
    },
    videos: null,
    link: 'https://www.amazon.com/dp/1733774912/ref=sr_1_3',
    poster: 'paperback-978-1-7337749-1-8-poster.jpg'
  }
];

export default books;
