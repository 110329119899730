import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledMain = styled.main`
  align-items: center;
  background-color: white;
  border-top: 5px solid ${props => props.theme.blue};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 77px);
  justify-content: center;
  padding: 0 3rem;

  @media (min-width: 80em) {
    height: calc(100vh - 115px);
  }

  .top {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 33vh;
    justify-content: center;

    h1 {
      font-size: 2.5rem;
      margin: 0 0 1rem;
    }

    h2 {
      font-size: 1.8rem;
      margin: 0 0 1rem;
    }

    p {
      line-height: 1.4;
      margin-top: 0;
    }
  }

  .bottom {
    min-height: 20vh;
    padding-bottom: 3rem;

    p {
      text-align: center;
    }
  }
`;

const MailListThanks = () => (
  <>
    <StyledMain role="main">
      <div className="top">
        <h1 className="funFont">Hooray!</h1>
        <h2>Subscription Confirmed!</h2>
        <p>Your subscription to our list has been confirmed.</p>
      </div>
      <div className="bottom">
        <img
          src="./images/kitty-sleeping-eye-open.png"
          alt="Kitty sleeping with one eye open"
        />
        <p>
          Now, why don't you <Link to="/">visit our homepage</Link> so I can get
          back to my nap.
        </p>
      </div>
    </StyledMain>
  </>
);

export default MailListThanks;
