import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledFooter = styled.footer`
  align-items: center;
  background-color: ${props => props.theme.blue};
  color: ${props => props.theme.white};
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100%;

  a {
    color: ${props => props.theme.white};
    text-decoration: none;
    transition: color 225ms ease-in-out;

    &:hover,
    &:focus {
      color: ${props => props.theme.yellow};
      text-decoration: underline;
    }
  }
`;

const Footer = () => {
  const [year, setYear] = useState('');

  useEffect(() => {
    const currentYear = new Date().getFullYear();

    if (currentYear === 2019) setYear(currentYear);
    else setYear(`2019-${currentYear}`);
  }, []);

  return (
    <StyledFooter>
      <p>Scruffy Puppy &copy;{year}</p>
      <p>
        <Link to="/privacy">Privacy</Link>
      </p>
    </StyledFooter>
  );
};

export default Footer;
