import React, { useState } from 'react';

const paths = {
  sleeping: './images/kitty-sleeping.png',
  annoyed: './images/kitty-annoyed.png'
};

const KittySleeping = () => {
  const { annoyed, sleeping } = paths;
  const [kittyPath, setKittyPath] = useState(sleeping);

  return (
    <img
      onMouseOver={() => setKittyPath(annoyed)}
      onMouseOut={() => setKittyPath(sleeping)}
      src={kittyPath}
      alt="kitty"
    />
  );
};

export default KittySleeping;
