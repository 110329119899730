import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import history from '../history';

import { AnchorButton } from '../components/Button';
import Header from '../components/Header';
import VideoPlayer from '../components/VideoPlayer';
import StyledDetails from '../styles/StyledDetails';

const Details = props => {
  const {
    description,
    details,
    image,
    link,
    poster,
    title,
    titleLead,
    videos
  } = props;

  useEffect(() => {
    if (!title) history.push('/');
  }, [title]);

  const createMarkup = text => {
    return { __html: text };
  };

  const MediaHeader = () => {
    if (videos) {
      return (
        <div className="details-videoplayer">
          <VideoPlayer />
        </div>
      );
    }
    return (
      <div>
        <img src={`./images/${poster}`} alt="promotional graphic" />
      </div>
    );
  };

  return (
    <>
      <Header small />
      <main role="main">
        <StyledDetails>
          <MediaHeader />
          <div className="details-info">
            <div className="details-title">
              <div className="details-image">
                <img src={`./images/${image}`} alt={`${titleLead} ${title}`} />
              </div>
              <div className="details-text">
                <h1 className="funFont">
                  {titleLead} {title}
                </h1>
                <div
                  className="details-description"
                  dangerouslySetInnerHTML={createMarkup(description)}
                />
                <p>
                  <AnchorButton
                    className="funFont"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Buy Now
                  </AnchorButton>
                </p>
              </div>
            </div>
            <h2 className="funFont">Details</h2>
            <ul className="details-list">
              <li>Format: {details.format}</li>
              <li>Pages: {details.pages}</li>
              <li>ISBN10: {details.isbn10}</li>
              <li>ISBN13: {details.isbn13}</li>
              <li>Dimensions: {details.dimensions}</li>
            </ul>
          </div>
        </StyledDetails>
      </main>
    </>
  );
};

const mapStateToProps = state => {
  return {
    description: state.description,
    details: state.details,
    image: state.image,
    link: state.link,
    poster: state.poster,
    title: state.title,
    titleLead: state.titleLead,
    videos: state.videos
  };
};

export default connect(mapStateToProps)(Details);
