import styled from 'styled-components';

const StyledLibrary = styled.section`
  background-color: ${props => props.theme.white};
  border-bottom: 5px solid ${props => props.theme.blue};
  /* border-top: 5px solid ${props => props.theme.pink}; */
  box-shadow: ${props => props.theme.bs};

  .container {
    margin: 0 auto;
    max-width: 71.25rem;
    padding: 3rem 0;

    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      text-align: center;

      @media (min-width: 42.5em) {
        font-size: 3rem;
      }
    }

    .bookshelf {
      display: flex;
      flex-direction: column;

      @media (min-width: 42.5em) {
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: repeat(2, 1fr);
        margin: 0 auto;
        max-width: 1140px;

        .book {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default StyledLibrary;
