import React from 'react';
import { Link } from 'react-router-dom';

import StyledHeader from '../styles/StyledHeader';
import KittySleeping from './KittySleeping';

const Header = props => {
  const HeaderLogo = () => (
    <div className="logo">
      <Link to="/">
        <img src="./images/logo-rgb.png" alt="Scruffy Puppy logo" />
      </Link>
    </div>
  );

  if (props.small) {
    return (
      <StyledHeader role="banner">
        <div className="container-small">
          <HeaderLogo />
        </div>
      </StyledHeader>
    );
  }

  return (
    <StyledHeader role="banner" style={{ borderBottom: '5px solid #015ca7' }}>
      <div className="container">
        <HeaderLogo />
      </div>
      <div className="kitty">
        <KittySleeping />
      </div>
    </StyledHeader>
  );
};

export default Header;
