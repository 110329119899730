import styled from 'styled-components';

const StyledDetails = styled.section`
  background-color: ${props => props.theme.white};
  border-bottom: 5px solid ${props => props.theme.blue};
  border-top: 5px solid ${props => props.theme.blue};

  @media (min-width: 75em) {
    border-bottom: none;
    border-left: 5px solid ${props => props.theme.blue};
    border-right: 5px solid ${props => props.theme.blue};
    border-top: 5px solid ${props => props.theme.blue};
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  }

  .details-info {
    padding: 3rem;
  }

  .details-text {
    font-size: 1.2rem;
  }

  .details-title {
    margin-bottom: 1.5rem;

    .details-image {
      display: flex;
      justify-content: center;
      margin: 0 auto 1.25rem;
      text-align: center;

      @media screen and (min-width: 80em) {
        display: block;
      }

      img {
        align-self: center;
        border: 1px solid ${props => props.theme.black};
        box-shadow: ${props => props.theme.bs};
        max-width: 20.625rem;
        transform: rotate(5deg);
        transition: transform 100ms ease-in-out;
        width: 100%;

        &:hover {
          transform: rotate(0);
        }
      }
    }

    h1 {
      font-size: 150%;
      font-weight: bold;
      line-height: 1.1;
      margin-bottom: 0.25rem;
    }

    .details-description {
      max-width: 65ch;

      p {
        margin-bottom: 1.5rem;
      }
    }

    @media (min-width: 53.75em) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;

      .details-title {
        .details-image {
          text-align: left;
        }
      }
    }
  }

  h2 {
    font-size: 120%;
    font-weight: bold;
  }
`;

export default StyledDetails;
