import styled from 'styled-components';

const Button = styled.button`
  background-color: ${props => props.theme.blue};
  border: 0;
  border-radius: 2rem;
  box-shadow: 4px 12px 16px rgba(0, 0, 0, 0.3);
  color: ${props => props.theme.white};
  cursor: pointer;
  font-size: 150%;
  padding: 0.5rem 2rem;
  text-shadow: 1px 1px 2px #000;
  text-transform: uppercase;
  transition: background-color 255ms ease-in-out;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.pink};
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
`;

export const PinkButton = styled(Button)`
  background-color: ${props => props.theme.pink};
  color: ${props => props.theme.white};

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: ${props => props.theme.purple};
  }
`;

export const AnchorButton = styled.a`
  background-color: ${props => props.theme.blue};
  border: 0;
  border-radius: 2rem;
  box-shadow: 4px 12px 16px rgba(0, 0, 0, 0.3);
  color: ${props => props.theme.white};
  cursor: pointer;
  display: inline-block;
  font-size: 150%;
  padding: 0.5rem 2rem;
  text-decoration: none;
  text-shadow: 1px 1px 2px #000;
  text-transform: uppercase;
  transition: background-color 255ms ease-in-out;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.pink};
    color: ${props => props.theme.white};
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
`;

export default Button;
