import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';

import history from '../history';
import GlobalStyle from '../styles/GlobalStyle';
import FontObserver from './FontObserver';

import Home from '../pages/Home';
import Details from '../pages/Details';
import Privacy from '../pages/Privacy';
import MailListThanks from '../pages/MailListThanks';
import NotFound from '../pages/NotFound';

import Footer from './Footer';
import ScrollToTop from './ScrollToTop';

const theme = {
  black: '#111',
  blue: '#015ca7',
  green: '#2fb255',
  lightblue: '#27bbd4',
  lime: '#77c232',
  gray: '#939393',
  grayLightest: '#ecedee',
  orange: '#ee6632',
  pink: '#e9008a',
  pinkDark: '#B20085',
  purple: '#6b4096',
  tan: '#e9dbc0',
  teal: '#adf0f2',
  white: '#fff',
  yellow: '#ff0',
  bs: '0 12px 16px rgba(0, 0, 0, 0.25)'
};

const StyledWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const App = () => {
  return (
    <>
      <GlobalStyle />
      <FontObserver />
      <ThemeProvider theme={theme}>
        <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
          <ScrollToTop>
            <StyledWrapper>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/details" exact component={Details} />
                <Route path="/privacy" exact component={Privacy} />
                <Route path="/thankyou" exact component={MailListThanks} />
                <Route component={NotFound} />
              </Switch>
            </StyledWrapper>
          </ScrollToTop>
          <Footer />
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
