import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from '../history';
import books from '../books';
import { setSelectedBook } from '../actions';

import Button from './Button';
import StyledBook from '../styles/StyledBook';

const Book = props => {
  const { titleLead, title, image, available, note = '', isbn10 } = props;

  const showDetails = e => {
    e.preventDefault();

    const book = books.reduce(function(result, element) {
      if (element.details.isbn10 === isbn10) {
        result.push(element);
      }
      return result;
    }, []);

    props.setSelectedBook(book);
    history.push('/details');
  };

  return (
    <StyledBook>
      <figure>
        <img src={image} alt={title} />
        <figcaption className="funFont">
          {titleLead}
          <br />
          {title}
        </figcaption>
      </figure>
      {available && (
        <div className="action">
          <Button className="funFont" onClick={e => showDetails(e)}>
            Learn More
          </Button>
        </div>
      )}
      {note && (
        <p className="note">
          <small>{note}</small>
        </p>
      )}
    </StyledBook>
  );
};

Book.propTypes = {
  titleLead: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  note: PropTypes.string,
  isbn10: PropTypes.string
};

export default connect(
  null,
  { setSelectedBook }
)(Book);
