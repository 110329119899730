const INITIAL_STATE = {
  titleLead: null,
  title: null,
  image: null,
  available: null,
  description: null,
  details: {
    format: 'paperback',
    isbn10: null,
    isbn13: null,
    pages: null,
    dimensions: '8.5 x 0.1 x 8.5 inches'
  },
  videos: null,
  link: null,
  poster: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_SELECTED_BOOK':
      const {
        available,
        description,
        details,
        image,
        title,
        titleLead,
        videos,
        link,
        poster
      } = action.payload;

      return {
        ...state,
        available,
        description,
        details,
        image,
        title,
        titleLead,
        videos,
        link,
        poster
      };

    default:
      return state;
  }
};
