import styled from 'styled-components';

const StyledContact = styled.section`
  padding: 5rem 0;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 28.125rem;
    text-align: center;

    p {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .social {
      align-items: center;
      display: flex;
      justify-content: center;

      a {
        margin: 0 0.5rem;
        transition: transform 100ms ease-in-out;

        &:hover {
          transform: translateY(-0.25rem);
        }

        img {
          width: 96px;
        }
      }
    }
  }
`;

export default StyledContact;
