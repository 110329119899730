import styled from 'styled-components';

const StyledHeader = styled.header`
  background: transparent;
  position: relative;

  .container {
    align-items: center;
    display: flex;
    height: 400px;
    justify-content: center;
    margin: 0 auto;

    .logo {
      align-items: center;
      display: flex;
      max-width: 500px;
      width: 80%;

      img {
        width: auto;
      }
    }
  }

  .container-small {
    height: 200px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .logo {
      max-width: 400px;
      padding: 0 2rem;
      text-align: center;

      @media (min-width: 75em) {
        padding: 0;
        text-align: left;
      }
    }
  }

  .kitty {
    position: absolute;
    bottom: -17px;
    right: 100px;
    width: 160px;
  }
`;

export default StyledHeader;
