import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledMain = styled.main`
  background-color: ${props => props.theme.white};
  border-top: 5px solid ${props => props.theme.blue};
  padding-bottom: 3rem;

  @media (min-width: 48em) {
    min-height: calc(100vh - 78px);
    padding-bottom: 0;
  }

  div {
    padding: 3rem 3rem 0;
  }

  h1 {
    color: ${props => props.theme.gray};
    font-size: 1.8rem;
    margin: 0 0 1rem;
  }

  h2 {
    font-size: 2.5rem;
    margin: 0 0 1rem;
  }

  p {
    line-height: 1.4;
    margin-top: 0;
  }
`;

const NotFound = () => (
  <>
    <StyledMain role="main">
      <div>
        <h1>404 Error</h1>
        <h2>Sorry, we can't seem to find what you're looking for.</h2>
        <p>
          You've landed on a URL that doesn't seem to exist. Would you like to{' '}
          <Link to="/">go to our homepage</Link>?
        </p>
      </div>
      <img
        src="./images/puppy-detective-ripped.jpg"
        alt="Puppy in detective outfit with magnifying glass"
      />
    </StyledMain>
  </>
);

export default NotFound;
