import styled from 'styled-components';

const StyledBook = styled.div`
  margin-bottom: 3rem;

  @media (min-width: 42.5rem) {
    margin-bottom: 0;
  }

  figure {
    margin-bottom: 0.5rem;

    img {
      border: 1px solid ${props => props.theme.black};
      box-shadow: ${props => props.theme.bs};
      margin-bottom: 1.5rem;
    }

    figcaption {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;

      @media (min-width: 42.5rem) {
        font-size: 1.75rem;
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
  }

  .note {
    text-align: center;
  }
`;

export default StyledBook;
