import React from 'react';
import uuid from 'uuid';

import books from '../books';
import StyledLibrary from '../styles/StyledLibrary';
import Book from './Book';

const Library = () => (
  <StyledLibrary>
    <div className="container">
      <h2 className="funFont">
        Scruffy Puppy
        <br />
        Picture Books
      </h2>
      <div className="bookshelf">
        {books.map(book => {
          const { titleLead, title, image, available, note = '', link } = book;

          return (
            <Book
              titleLead={titleLead}
              title={title}
              image={`./images/${image}`}
              key={uuid.v4()}
              available={available}
              note={note}
              isbn10={book.details.isbn10}
              link={link}
            />
          );
        })}
      </div>
    </div>
  </StyledLibrary>
);

export default Library;
